import {
  Typography, Grid, Accordion, AccordionSummary, AccordionDetails,
  TextField, Checkbox, FormControlLabel, Select, MenuItem, InputLabel, FormControl, IconButton
} from "@mui/material";
import SortIcon from "@mui/icons-material/Sort";
import { useDispatch, useSelector } from "react-redux";
import { setGame } from '../../redux/boardgameDialog';
import { setAllGames } from '../../redux/app';

// import gameJson from './BGStats'
import BoardgamesCard from "./BoardgameCard.jsx";
import BoardgameDialog from "./BoardgameDialog.jsx";
import { fetchAllGames, updateGames } from '../../service/backendService';
import { useState } from "react";
import { debounce } from "lodash";
import { Redirect, Route, Switch, useHistory } from "react-router-dom";

const doesOwnTheGame = (game) => {
  let owned = false;
  for (let copy of game.copies) {
    if (copy.statusOwned) {
      owned = true;
      break;
    }
  }
  return owned;
}

const didOwnTheGame = (game) => {
  let owned = false;
  for (let copy of game.copies) {
    if (copy.statusPrevOwned) {
      owned = true;
      break;
    }
  }
  return owned;
}

const willOwnTheGame = (game) => {
  let owned = false;
  for (let copy of game.copies) {
    if (copy.statusPreordered) {
      owned = true;
      break;
    }
  }
  return owned;
}

const dontOwnTheGame = (game) => {
  let owned = false;
  for (let copy of game.copies) {
    if (copy.statusPreordered || copy.statusPrevOwned || copy.statusOwned) {
      owned = true;
      break;
    }
  }
  return !owned;
}

const Boardgames = () => {
  const dispatch = useDispatch();
  const queries = window.location.search;
  const queryMap = {};
  if (queries.length) {
    queries.replace('?', '').split("&").forEach((query) => {
      const [key, value] = query.split("=");
      queryMap[key] = value;
    })
  };
  const filterTag = queryMap.tag ? `#${queryMap.tag}` : queryMap.filter || '';
  const sortTag = queryMap.sort ? queryMap.sort : 'name';
  const sortTagAsc = queryMap.order ? queryMap.order : 'asc';
  const [filter, setFilter] = useState(filterTag);
  const [ownedChecked, setOwnedChecked] = useState(true);
  const [prevOwnedChecked, setPrevOwnedChecked] = useState(false);
  const [preOrderedChecked, setPreOrderedChecked] = useState(false);
  const [ordering, setOrdering] = useState(sortTag);
  const [orderingAsc, setOrderingAsc] = useState(sortTagAsc === 'desc' ? -1 : 1);
  const allGames = useSelector((state) => state.app.allGames);
  const userDetails = useSelector(state => state.app.user);
  const isAdmin = userDetails && userDetails['https://ProfDeCube.com'].roles.includes('ADMIN');
  const history = useHistory();


  const doGameFetch = async () => {

    const json = {};
    const games = await fetchAllGames();
    if (games) {
      json.games = games.filter((game) => !dontOwnTheGame(game) && game.isBaseGame);
      json.expansions = games.filter((game) => !dontOwnTheGame(game) && !game.isBaseGame);
      dispatch(setAllGames(json));
    }
  }

  if (allGames.games.length === 0) doGameFetch()

  const showDialog = (game) => {
    dispatch(setGame(game));
  }

  const filterGames = (game) => {
    let included = false
    if (filter.startsWith('#')) {
      if (!game.tags) {
        return false;
      }
      if (filter.length === 1) return true;
      if (!game.tags
        .some(
          (tag) => {
            return tag.toLowerCase().replace(/\s*/g, '').startsWith(filter.replace('#', '').replace(/\s*/g, ''))
          }
        )
      ) {
        return false;
      }
    } else {
      if (!game.name.toLowerCase().includes(filter) && !game.bggYear.toString().includes(filter)) return false;
    }
    if (ownedChecked && doesOwnTheGame(game)) included = true
    if (prevOwnedChecked && didOwnTheGame(game)) included = true
    if (preOrderedChecked && willOwnTheGame(game)) included = true
    //if (!ownedChecked && !prevOwnedChecked && !preOrderedChecked) return dontOwnTheGame(game);
    return included;
  }

  const debouncedFilterText = debounce((value) => setFilter(value.toLowerCase()), 500)
  const setFilterText = (event) => {
    debouncedFilterText(event.target.value)
  }

  const handleOwnedChange = (event, checked) => setOwnedChecked(checked);
  const handlePrevOwnedChange = (event, checked) => setPrevOwnedChecked(checked);
  const handlePreOrderedChange = (event, checked) => setPreOrderedChecked(checked);
  const handleOrderingChange = (event) => setOrdering(event.target.value);

  const filteredGames = allGames.games.filter(filterGames)
  const filteredExpansions = allGames.expansions.filter(filterGames)

  const fileUpload = async (event, file) => {
    if (isAdmin) {
      const jsonText = await event.target.files.item(0).text();
      const didUpdate = await updateGames(jsonText);
      if (didUpdate) {
        doGameFetch();
        history.push('/boardgames')
      }
    }
  }

  const sortFunc = (a, b) => {
    if (ordering === 'name') return a.name >= b.name ? 1 * orderingAsc : -1 * orderingAsc;
    if (ordering === 'year') return a.bggYear >= b.bggYear ? 1 * orderingAsc : -1 * orderingAsc;
    if (ordering === 'weight') return a.weight >= b.weight ? 1 * orderingAsc : -1 * orderingAsc;
    if (ordering === 'players') return a.minPlayerCount >= b.minPlayerCount ? 1 * orderingAsc : -1 * orderingAsc;
    if (ordering === 'time') return ((a.minPlayTime + a.maxPlayTime) / 2) >= ((b.minPlayTime + b.maxPlayTime) / 2) ? 1 * orderingAsc : -1 * orderingAsc;
  }
  const toggleOrder = () => {
    setOrderingAsc(orderingAsc * -1)
  }
  const selectOptions = [
    <MenuItem key="name" value="name">Name</MenuItem>,
    <MenuItem key="players" value="players">Players</MenuItem>,
    <MenuItem key="year" value="year">Year</MenuItem>,
    <MenuItem key="weight" value="weight">Weight</MenuItem>,
    <MenuItem key="time" value="time">Play Time</MenuItem>,
  ]
  return (
    <Switch>
      <Route exact path="/boardgames/update">
        {!isAdmin && <Redirect to="/boardgames" />}
        Update Games
        <input type="file" onChange={fileUpload}></input>
      </Route>
      <Route path="/">
        <Grid container justifyContent="space-evenly" sx={{ margin: '12px' }}>
          <Grid container width="fit-content">
            <FormControlLabel control={<Checkbox defaultChecked onChange={handleOwnedChange} />} label="Owned" />
            <FormControlLabel control={<Checkbox onChange={handlePrevOwnedChange} />} label="Prev. Owned" />
            <FormControlLabel control={<Checkbox onChange={handlePreOrderedChange} />} label="Pre-ordered" />
          </Grid>
          <Grid container width="fit-content">
            <TextField defaultValue={filterTag} onChange={setFilterText} label="Filter"></TextField>
            <FormControl>
              <InputLabel id="order-by-label">Order By</InputLabel>
              <Select sx={{ width: '130px' }} defaultValue={ordering} onChange={handleOrderingChange} id="order-by" labelId="order-by-label" label="Order By">{selectOptions}</Select>
            </FormControl>
            <IconButton>
              <SortIcon sx={{transform: `scaleY(${orderingAsc * -1})`}} onClick={toggleOrder}/>
            </IconButton>
          </Grid>
        </Grid>
        <Accordion defaultExpanded>
          <AccordionSummary>
            <Typography variant="h4">{`Games (${Object.keys(filteredGames).length}/${Object.keys(allGames.games).length})`}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container>
              {filteredGames
                .sort(sortFunc)
                .map((boardgame, i) => (
                  <Grid onClick={() => showDialog(boardgame)} item xs={6} sm={3} md={2} key={boardgame.bggId + '-' + i} >
                    <BoardgamesCard boardgame={boardgame} sorting={ordering} />
                  </Grid>
                ))}
            </Grid>
          </AccordionDetails>
        </Accordion>
        <Accordion defaultExpanded>
          <AccordionSummary>
            <Typography variant="h4">{`Expansions (${Object.keys(filteredExpansions).length}/${Object.keys(allGames.expansions).length})`}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container>
              {filteredExpansions
                .sort(sortFunc)
                .map((boardgame, i) => (
                  <Grid onClick={() => showDialog(boardgame)} item xs={6} sm={3} md={2} key={boardgame.bggId + '-' + i} >
                    <BoardgamesCard boardgame={boardgame} sorting={ordering} />
                  </Grid>
                ))}
            </Grid>
          </AccordionDetails>
        </Accordion>
        <BoardgameDialog />
      </Route>
    </Switch>
  );
}

export default Boardgames;
