import React from 'react';
import { useSelector } from 'react-redux'
import { Card, Typography, Grid } from '@mui/material';

// const status = (game) => {
//   if (game.owned) return 'Owned';
//   if (game.prevowned) return 'Previously Owned';
//   if (game.preordered) return 'Pre-Ordered';
// }

const BoardgamesCard = ({ boardgame, sorting }) => {
  const theme = useSelector(state => state.app.theme)
  const cardColour = (theme === 'dark') ? '#FFFFFF01' : '#00000020';
  let bracketText = '';
  switch(sorting) {
    case "weight":
      bracketText = `( ${boardgame.weight} )`;
      break;
    case "year":
      bracketText = `( ${boardgame.bggYear} )`;
      break;
    case "players":
      bracketText = `( ${boardgame.minPlayerCount} - ${boardgame.maxPlayerCount} )`;
      break;
    case "time": 
      bracketText = `( ${boardgame.minPlayTime} - ${boardgame.maxPlayTime} )`;
      break;
    default:
      break;
  }
  return (
    <Grid container direction="column" alignItems="center" sx={{ padding: '10px', height: '100%' }}>
      <Card sx={{
        width: '100%',
        height: '100%',
        textAlign: 'center',
        padding: '10px',
        display: 'grid',
        gridTemplateColumns: '1fr',
        gridTemplateRows: '150px 1fr',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'flex-start',
        backgroundColor: cardColour,
      }}>
        <Grid sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', height: '150px', width: 'fit-content', margin: 'auto' }}>
          <img src={boardgame.urlThumb} alt={`box art for ${boardgame.name}`} style={{ width: '100%' }}></img>
        </Grid>
        <Card sx={{
          marginTop: '8px',
          height: 'calc(100% - 8px)',
          width: '100%',
          backgroundColor: cardColour,
          padding: '4px'
        }}>
          <Typography>{`${boardgame.name} ${bracketText.replace(/ /g, "\u00A0")}`}</Typography>
          {/* <Typography>{status(boardgame)}</Typography> */}
        </Card>
      </Card>
    </Grid>
  )
}

export default BoardgamesCard;