/* eslint-disable no-restricted-globals */
import React from 'react';
import { Typography, AppBar, Toolbar, IconButton } from "@mui/material";
import { Menu } from '@mui/icons-material'
import { useSelector, useDispatch } from "react-redux";
import { toggleNavigation } from "../../redux/app";
import UserMenu from './UserMenu';

const Home = () => {
  const dispatch = useDispatch();
  const pageTitle = useSelector(state => state.app.pageTitle);

  if(location.search.includes('display=true')) return null;

  const openNavigation = () => {
    dispatch(toggleNavigation());
  }

  return (
    <AppBar position="static">
      <Toolbar>
        <IconButton color="inherit" onClick={openNavigation}>
          <Menu />
        </IconButton>
        <Typography variant="h6" component="div" sx={{ flexGrow: 1, marginLeft: '24px' }}>
          {pageTitle}
        </Typography>
        <UserMenu />
      </Toolbar>
    </AppBar>
  );
}

export default Home;
